.manageEventSponsorsPageWrapper {
    padding: 2rem 2rem;
    $maroon: #7B1B2A;
    $clouds: #ecf0f1;

    // General
    body {
        color: $maroon;
        background: $clouds;
        padding: 0 1em 1em;
    }

    h1 {
        margin: 0;
        line-height: 2;
        text-align: center;
    }

    h2 {
        margin: 0 0 .5em;
        font-weight: normal;
    }

    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    // Layout
    .row {
        display: flex;

        .col {
            flex: 1;

            &:last-child {
                margin-left: 1em;
            }
        }
    }

    /* Accordion styles */
    .tabs {
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
    }

    .tab {
        width: 100%;
        color: white;
        overflow: hidden;

        &-label {
            display: flex;
            justify-content: space-between;
            padding: 1em;
            background: $maroon;
            font-weight: bold;
            cursor: pointer;

            /* Icon */
            &:hover {
                background: darken($maroon, 10%);
            }

            &::after {
                content: "\276F";
                width: 1em;
                height: 1em;
                text-align: center;
                transition: all .35s;
            }
        }

        &-content {
            max-height: 0;
            padding: 0 1em;
            color: $maroon;
            background: white;
            transition: all .35s;
        }

        &-close {
            display: flex;
            justify-content: flex-end;
            padding: 1em;
            font-size: 0.75em;
            background: $maroon;
            cursor: pointer;

            &:hover {
                background: darken($maroon, 10%);
            }
        }
    }

    // :checked
    input:checked {
        +.tab-label {
            background: darken($maroon, 10%);

            &::after {
                transform: rotate(90deg);
            }
        }

        ~.tab-content {
            max-height: 100vh;
            padding: 1em;
        }
    }

    .sponsorsListWrapper {
        max-height: 800px;
        overflow-y: scroll;
        padding-bottom: 10rem;
        overflow-x: hidden;

        .sponsor {
            height: 150px;
            width: 100%;
            background-color: #ffffff;
            border: 1px solid $maroon;
            display: flex;
            justify-content: center;
            cursor: pointer;
            box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
            align-items: center;

            img {
                object-fit: contain;
                height: 70px;
            }

            &:hover {
                background-color: #ffffff;
            }

            margin-bottom: 25px;
            position: relative;

            .sponsorName {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                text-align: center;
                padding-bottom: 5px;
            }
        }

        .addNewSponsor {
            display: flex;
            justify-content: end;
            align-items: center;
            margin-bottom: 35px;

            button {
                border: none;
                box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);
                cursor: pointer;
                background-color: $maroon;
                color: white;
                border-radius: 5px;
                padding: 8px 15px 10px 15px;
            }
        }
    }
}

.dayLabel {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 600;
}


.cloneSpeakerWrapper {
    position: absolute;
    top: 7px;
    right: 7px;

    .actionButton {
        font-size: 0.7rem;
        background-color: #dfdfdf;
        padding: 3px 8px;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    }

    .speakerClonerInnerWrapper {
        position: absolute;
        box-shadow: 8px 8px 15px rgba(0, 0, 0, 0.3);
        top: 0px;
        right: 0px;
        width: 350px;
        min-height: 130px;
        background-color: white;
        border: 1px solid #7B1B2A;
        z-index: 9999;
        border-radius: 5px;

        .inputWrapper {
            padding: 6px 15px 0 15px;

            .label {
                font-size: 0.8rem;
            }

            select {
                width: 80%;
                font-size: 0.7rem;
                option {
                    font-size: 0.7rem;
                }
            }
        }

        .submitWrapper {
            padding-right: 10px;
            padding-top: 20px;
            padding-bottom: 10px;
            // width: 100%;
            float: right;
            display: flex;

            .dismissBtn {
                font-size: 0.7rem;
                padding: 6px 25px;
            }

            .cloneBtn {
                font-size: 0.7rem;
                padding: 6px 25px;
                background-color: #7B1B2A;
                color: white;
                border-radius: 5px;
                cursor: pointer;
                box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
            }
        }
    }
}