.register-page-wrapper {
  background-image: url("https://img1.goodfon.com/wallpaper/nbig/3/ef/samolet-airplane-passazhirskiy.jpg");
  background-position: center;
  background-size: cover;

  .content-wrapper {
    background-color: rgba(155, 0, 26, 0.795) !important;
  }

  .brand-logo {
    img {
      width: 157px !important;
    }
  }

  .disclaimerWrapper {
    font-size: 0.8rem;
    font-weight: 600;
    border: 1px solid rgba(155, 0, 26, 0.795);
    border-radius: 7px;
    color: rgba(155, 0, 26, 0.795);
    padding: 8px 15px;
  }
  .banner {
    img {
      width: 100%;
      object-fit: contain;
    }
  }
  .brand-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 300px!important;
    }
  }
  .emailNote {
    margin-top: 15px;
    margin-bottom: 25px;
    font-size: 0.8rem;
    font-weight: 600;
    border: 1px solid rgba(155, 0, 26, 0.795);
    border-radius: 7px;
    color: rgba(155, 0, 26, 0.795);
    padding: 8px 15px;
  }
}