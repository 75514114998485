.manageEventSponsorsPageWrapper {
    padding: 2rem 2rem;
    $maroon: #7B1B2A;
    $clouds: #ecf0f1;







    .eventCard {
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0,0,0,0.20);
        position: relative;
        top: 0;
        cursor: pointer;
        transition: 0.2s;
        // overflow: hidden;
        margin-bottom: 32px;
        position: relative;

        .eventCardInner {
            padding: 20px;
    
            .imageWrapper {
                width: 100%;
                height: 150px;
                overflow: hidden;
                border-radius: 8px;
                background-color: #efefef;
                padding: 8px;
                margin-bottom: 8px;
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
    
            .eventDetails {
                .eventTitle {
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 24px;
                }
    
                .eventDuration,
                .eventSlots {
                    font-size: 14px;
                    color: rgba(0,0,0,0.5);
    
                    i {
                        width: 16px;
                        height: 16px;
                    }
                    
                    span {
                        font-weight: 600;
                    }
                }
            }
        }

        .eventCardFooter {
            background-color: $maroon;
            color: #fff;
            text-align: center;
            padding: 0 16px;
            height: 0;
            transition: 0.2s;
            position: absolute;
            top: calc(100%);
            left: 0;
            right: 0;
            border-radius: 0 0 8px 8px;
        }
        
        &:hover {
            top: -8px;
            box-shadow: 0 0 50px rgba(0,0,0,0.25);
            border-radius: 8px 8px 0 0;
            
            .eventCardFooter {
                height: 38px;
                padding: 8px 16px;
            }
        }
    }

    .addEditEventModal {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 9999;
        display: none;
        align-items: center;
        justify-content: center;

        &.show {
            display: flex;
        }
        
        .overlay {
            background: rgba(0,0,0,0.25);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .modalContainer {
            background-color: #fff;
            padding: 24px;
            position: relative;
            max-width: 600px;
            width: 100%;

            .modalHeader {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 32px;
            }

            .modalContent {
                input {
                    opacity: 1;
                    position: relative;
                    z-index: 1;
                }
                
                .slots {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    gap: 8px;
                    margin-top: 8px;

                    .slot {
                        border: 1px solid #6e6e6e;
                        color: #000;
                        background: #efefef;
                        font-size: 12px;
                        text-align: center;
                        display: grid;
                        grid-template-columns: 1fr auto;
                        align-items: center;
                        gap: 4px;

                        span {
                            display: block;
                            padding: 4px;
                        }

                        i {
                            padding: 4px;
                        }
                    }
                }
            }
        }
    }
}
