.manageEventSponsorsPageWrapper {
    padding: 2rem 2rem;
    $maroon: #7B1B2A;
    $clouds: #ecf0f1;

    // General
    body {
        color: $maroon;
        background: $clouds;
        padding: 0 1em 1em;
    }

    h1 {
        margin: 0;
        line-height: 2;
        text-align: center;
    }

    h2 {
        margin: 0 0 .5em;
        font-weight: normal;
    }

    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }

    // Layout
    .row {
        display: flex;

        .col {
            flex: 1;

            &:last-child {
                margin-left: 1em;
            }
        }
    }

    /* Accordion styles */
    .tabs {
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
    }

    .tab {
        width: 100%;
        color: white;
        overflow: hidden;

        &-label {
            display: flex;
            justify-content: space-between;
            padding: 1em;
            background: $maroon;
            font-weight: bold;
            cursor: pointer;

            /* Icon */
            &:hover {
                background: darken($maroon, 10%);
            }

            &::after {
                content: "\276F";
                width: 1em;
                height: 1em;
                text-align: center;
                transition: all .35s;
            }
        }

        &-content {
            max-height: 0;
            padding: 0 1em;
            color: $maroon;
            background: white;
            transition: all .35s;
        }

        &-close {
            display: flex;
            justify-content: flex-end;
            padding: 1em;
            font-size: 0.75em;
            background: $maroon;
            cursor: pointer;

            &:hover {
                background: darken($maroon, 10%);
            }
        }
    }

    // :checked
    input:checked {
        +.tab-label {
            background: darken($maroon, 10%);

            &::after {
                transform: rotate(90deg);
            }
        }

        ~.tab-content {
            max-height: 100vh;
            padding: 1em;
        }
    }

    .sponsorsListWrapper {
        max-height: 800px;
        overflow-y: scroll;
        overflow-x: hidden;
      .sponsor {
        height: 150px;
        width: 100%;
        background-color: #ffffff;
        border: 1px solid $maroon;
        display: flex;
        justify-content: center;
        cursor: pointer;
        box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
        align-items: center;
        img {
          object-fit: contain;
          height: 70px;
        }
        &:hover {
          background-color: #ffffff;
        }
        margin-bottom: 25px;
        position: relative;
        .sponsorName {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            padding-bottom: 5px;
        }
      }

      .addNewSponsor {
        display: flex;
        justify-content: end;
        align-items: center;
        margin-bottom: 35px;
        button {
          border: none;
            box-shadow: 10px 10px 15px rgba(0,0,0,0.2);
          cursor: pointer;
          background-color: $maroon;
          color: white;
          border-radius: 5px;
          padding: 8px 15px 10px 15px;
        }
      }
    }




}


.featuredSponsorWrapper {
    &.featured {
        background-color: rgb(255, 217, 0);
    }

    input {
        z-index: 99!important;
        opacity: 1!important;
        padding-top: 5px!important;
    }
    span {
        padding-left: 15px;
    }
    position: absolute;
    top: 0px;
    right: 10px;
    background-color: rgb(224, 224, 224);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    color: black;
    font-weight: 600;
    font-size: 0.8rem;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
    padding: 8px 35px;
}