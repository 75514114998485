
  .yearTabsWrapper {
    width: 100%;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    .yearTab {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      background-color: #d0d0d060;
      border-radius: 9px;
      font-size: 1.5rem;
      margin: 0 15px;
      cursor: pointer;
      &.active {
      background-color: #8f0000!important;
      color: white;
      }
            &:hover {
      background-color: #99999993;
      // color: white;
      }
    }
  }