.richTextEditorWrapper {
    .ck.ck-editor__main {
        &>.ck-editor__editable {
            height: 45vh;
        }
    }
}

:root {
    /* Because maximum bootstrap z-index is 1070. */
     --ck-z-default: 10000001
  }