.supportBtnWrapper {
    width: 100%;
    font-size: 0.7rem;
    margin-top: 55px;
    margin-bottom: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 200px;
      border: none;
      outline: none;
      background-color: rgba(202, 202, 202, 0.685);
      color: black;
      padding: 7px 15px;
      margin-left: 15px;
      cursor: pointer;
      &:hover {
        background-color: rgb(202, 202, 202);
      }
    }
  }

  .contactSupportModalHeader {
    margin: 15px 0 35px 0;
  }

  

  .supportModalWrapper {
      position: relative;
    .dismissSupportModal {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 15px;
        height: 15px;
        border-radius: 15px;
        background-color: rgb(218, 218, 218);
        color: rgb(0, 0, 0);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        font-weight: 600;
      }
      textarea {
        height: 100px;
      }
      button {
        height: 30px;
        line-height: 0!important;
      }
  }