.phoneNumberWrapper {
  width: 100%;
  display: flex;
  border: 1px solid #dee2e6;
  font-weight: 400;
  font-size: 0.875rem;
  border-radius: 4px;
  height: 2rem;
  min-height: 3.175rem;
  justify-content: space-between;
  position: relative;

  .codeButton {
    border-radius: 0px;
    border-right: none;
    border-color: #d6d6d6;
    height: 45px;
    padding: 0px 8px;
    padding-right: 8px;
  }

  .dropDownTrigger {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #ffffff;
    border-left: none;
    padding: 0px;
    padding-right: 8px;
  }

  .input {
    height: 45px;
  }

  .input:focus,
  .input[type]:focus,
  .uneditable-input:focus {
    border-color: #d6d6d6;
    box-shadow: 0 1px 1px rgba(229, 102, 23, 0) inset, 0 0 8px rgba(229, 102, 23, 0);
    outline: 0 none;
  }

  .flagsViewerWrapper {
    max-height: 200px;
    width: 100%;
    overflow-y: scroll;

    .flagViewerWrapper {
      width: 100%;
      display: flex;
      padding: 10px 15px;
      justify-content: start;
      align-items: center;

      img {
        width: 28px;
      }

      &>div {
        padding-top: 2px;
        padding-left: 20px;
        font-size: 0.8rem;
      }

      cursor: pointer;
    }

    .flagViewerWrapper:hover {
      background-color: rgb(197, 233, 255) !important;
      color: rgb(77, 77, 77) !important;
    }

    .selected,
    .selected:hover {
      // background-color: #0078C1 !important;
      // color: white !important;
      // cursor: not-allowed;
    }
  }

  .flagsViewerSearch {
    width: 100%;

    input {
      width: 100%;
      border: 1px solid rgb(255, 255, 255);
      margin-bottom: 7px;
      padding: 5px 10px;
      font-size: 0.8rem;
    }

    input:focus {
      outline: none;
    }
  }
}