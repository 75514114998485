.sponsorImageEditorWrapper {
    padding: 2rem 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    img {
        height: 50px;
        object-fit: contain;
    }

    &:hover {
        background-color: rgb(240, 240, 240);
    }

    position: relative;

    .hintText {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 0.7rem;
        font-weight: 600;
    }
}