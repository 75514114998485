.profileErrorText {
  height: 5px;
  font-size: 0.7rem;
  font-weight: 600;
  color: red;
  padding-top: 16px;
  padding-left: 5px;
}

.profile-page-outer-wrapper {
  .col-form-label {
    padding-top: 5px;
  }
  .form-control, .asColorPicker-input, .dataTables_wrapper select, .select2-container--default .select2-selection--single, .select2-container--default .select2-selection--single .select2-search__field, .typeahead, .tt-query, .tt-hint {
    height: 3rem!important;
  }
}



/* CSS to make the checkbox bigger and more noticeable */
.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.checkbox-container input[type="checkbox"] {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #000; /* Add a border */
  appearance: none; /* Remove default styling */
  -webkit-appearance: none;
  outline: none;
  background-color: #fff;
  border-radius: 3px; /* Optional: gives a rounded corner look */
}

.checkbox-container input[type="checkbox"]:checked {
  background-color: #007bff; /* Change the background color when checked */
  border-color: #007bff; /* Change border color when checked */
}

.checkbox-container label {
  font-size: 16px; /* Make the label text larger for better visibility */
  font-weight: bold;
}
