.profile-image {
  width: 50px;
}

.navbar .navbar-brand-wrapper .navbar-brand img {
  max-width: 100%;
  height: 27px;
  margin: auto;
  vertical-align: middle;
}

.login-page-wrapper .brand-logo img {
  width: 133px !important;
  /* height: 30px; */
}


.codeSelector {
  .input-group-prepend {
    background-color: rgb(255, 255, 255);
    // height: 48px;
    display: flex;
  }
}

.phoneSelector {

  /* .dropdown-menu.show {
  top: -16px!important;
  left: -323px!important;
  transform: unset!important;
  width: 100%;
} */

  .dropdown-toggle-split.btn.btn-outline-secondary {
    /* display: none!important; */
  }

  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after,
  .sr-only {
    color: aliceblue;
  }

  /*.input-group, .asColorPicker-wrap  {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: unset!important;
}

.selectedCountry {
  cursor: pointer;
} */

  .countryLabel,
  .input-group-prepend {
    width: 100%;
    background-color: rgb(255, 255, 255);
  }

  .btn-secondary.btn-light,
  .btn-secondary.btn-secondary {
    color: #1E283D;
    display: flex;
    justify-content: space-between;
  }

  .countrySelectionLabel {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .countrySelectionLabel img {
    width: 25px;
    margin-right: 15px;
  }

  .noCountrySelected {
    color: #bbbbbb;
  }

  .countryLabel,
  .input-group-prepend {
    width: 100%;
    background-color: rgb(255, 255, 255);
    height: 48px;
  }
}



.sidebar {
  min-height: calc(100vh - 97px);
  background: #F4F5F7;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  padding: 0;
  width: 308px !important;
}

.sidebar-icon-only .sidebar.sidebar-offcanvas {
  width: 70px !important;
}

.navbar .navbar-menu-wrapper {
  width: calc(100% - 320px) !important;
}

.navbar .navbar-brand-wrapper {
  width: 320px !important;
}

.sidebar-icon-only .navbar .navbar-brand-wrapper {
  width: 70px !important;
}

.sidebar-icon-only .navbar .navbar-menu-wrapper {
  width: calc(100% - 120px) !important;
}

.deleteRegBtn {
  border: none;
  background-color: brown;
  color: white;
  border-radius: 100px;
  height: 25px;
  width: 25px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editRegBtn {
  border: none;
  background-color: rgb(0, 0, 0);
  color: white;
  border-radius: 100px;
  margin-right: 15px;
  height: 25px;
  width: 25px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}