.emailLogsModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent;
    }
  
    .modalContainer {
      background: #fff;
      width: 500px;
      max-width: 90%;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
      position: relative;
      display: flex;
      flex-direction: column;
  
      .modalHeader {
        background: #004085;
        color: #fff;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        h3 {
          margin: 0;
          font-size: 18px;
        }
  
        .close {
          cursor: pointer;
          font-size: 18px;
        }
      }
  
      .modalContent {
        padding: 20px;
        max-height: 400px;
        overflow-y: auto;
  
        table {
          width: 100%;
          border-collapse: collapse;
  
          th, td {
            padding: 10px;
            border-bottom: 1px solid #ddd;
            text-align: left;
          }
  
          th {
            background: #f8f9fa;
            font-weight: bold;
          }
  
          a {
            color: #007bff;
            text-decoration: none;
  
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
  
      .modalFooter {
        padding: 15px;
        display: flex;
        justify-content: flex-end;
        background: #f8f9fa;
      }
  
      .btn-secondary {
        background: #6c757d;
        color: #fff;
        border: none;
        padding: 8px 12px;
        cursor: pointer;
        border-radius: 4px;
  
        &:hover {
          background: #5a6268;
        }
      }
    }
  }
  