
.slotDetailModal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    display: none;
    align-items: center;
    justify-content: center;
  
    &.show {
      display: flex;
    }


    .form-check {
      display: flex;
      align-items: center;
      margin-top: 1rem;
    }
    
    .form-check-input {
      margin-right: 0.5rem;
      margin-top: 0;
      margin-left: unset;
    }
    
    .form-control {
      margin-top: 1rem;
    }

    .form-check-label {
      margin-left: 0.5rem;
    }

    input {
        position: unset;
        opacity: unset;
        z-index: unset;
    }
  
    .overlay {
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    
      .modalContainer {
        background-color: #fff;
        padding: 24px;
        position: relative;
        max-width: 500px;
        width: 100%;
    
        .modalHeader {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 16px;
        }
    
        .modalContent {
          .form-group {
            margin-bottom: 16px;
    
            label {
              font-weight: bold;
              display: inline;
              margin-right: 8px;
            }
    
            .form-value {
              display: inline-block;
              color: #333;
              padding: 4px 8px;
              background: #f8f8f8;
              border: 1px solid #ddd;
              border-radius: 4px;
            }
    
            .d-flex {
              display: flex;
              align-items: center;
    
              .btn {
                margin-left: 8px;
              }
    
              .flex-grow-1 {
                flex-grow: 1;
              }
            }
          }
        }
    
        .modalFooter {
          display: flex;
          align-items: center;
          justify-content: space-between;
    
          .btn {
            padding: 8px 16px;
          }
        }
      }
    }
    

    