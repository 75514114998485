.sponsorDetailsEditorModal {
    $maroon: #7B1B2A;

    .header {
        width: 100%;
        background-color: $maroon;
        color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 20px;

        .leftPart {
            font-weight: 600;
        }

        .rightPart {
            button {
                margin-right: 15px;
                padding: 3px 15px;
            }
        }
    }

    .sponsorDetailsEditorInnerWrapper {
        padding-top: 2rem;
        padding-bottom: 2rem;
        max-height: 88vh;
        overflow-y: scroll;
        overflow-x: hidden;

        .inputWrapper {
            margin-top: 2rem;

            .label {
                margin-bottom: 5px;
            }

            input {
                border: 1px solid rgb(192, 192, 192);
                padding: 8px 15px;
                width: 100%;
            }
        }
    }
    .actionBtns {
       border: none;
    //    font-weight: 600; 
       &.deleteBtn {
        background-color: rgb(197, 0, 0);
        color: white;
       }
       &.saveBtn {
        background-color: rgb(29, 177, 0);
        color: rgb(255, 255, 255);
       }
       &.cancelBtn {
        background-color: rgb(255, 255, 255);
        color: rgb(19, 19, 19);
       }
    }
}

#ct-container {
    z-index: 9999999 !important;
}