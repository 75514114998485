.bulkHoursUploadPopup {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    display: none;
    align-items: center;
    justify-content: center;
  
    &.show {
      display: flex;
    }

    select.form-control {
      color: #000;
    }

    // input type checkbox
    input[type="checkbox"] {
      width: unset!important;
    }
  
    .overlay {
      background: rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  
    .modalContainer {
      background-color: #fff;
      padding: 24px;
      position: relative;
      max-width: 500px; /* Slightly narrower than the existing modal */
      width: 100%;
  
      .modalHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px; /* Slightly reduced margin */
        
        h3 {
          margin: 0;
          font-size: 18px;
          font-weight: bold;
        }
  
        .close {
          cursor: pointer;
          font-size: 16px;
        }
      }
  
      .modalContent {
        .form-group {
          margin-bottom: 16px;
  
          label {
            display: block;
            font-weight: bold;
            margin-bottom: 8px;
            font-size: 14px;
          }
  
          input,
          select {
            width: 100%;
            padding: 8px;
            font-size: 14px;
            border: 1px solid #ccc;
            border-radius: 4px;
          }
  
          button {
            background-color: #007bff;
            color: white;
            padding: 8px 16px;
            font-size: 14px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            width: 100%;
            text-align: center;
  
            &:hover {
              background-color: #0056b3;
            }
          }
  
          input[type="checkbox"] {
            margin-right: 8px;
            transform: scale(1.2);
          }
        }
      }
  
      .modalFooter {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;
  
        .btn {
          padding: 8px 16px;
          font-size: 14px;
          border-radius: 4px;
  
          &.btn-secondary {
            background-color: #6c757d;
            color: white;
            margin-right: 8px;
  
            &:hover {
              background-color: #5a6268;
            }
          }
  
          &.btn-primary {
            background-color: #007bff;
            color: white;
  
            &:hover {
              background-color: #0056b3;
            }
          }
        }
      }
    }
  }
  