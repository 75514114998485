.eventSideBarOuterWrapper {
    transition: 0s !important;
    position: fixed;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.311);
    width: 0vw;
    height: 100vh;
    z-index: 999999;

    &.show {
        width: 100vw;
    }

    .eventSideBarWrapper {
        transition: 0.3s !important;
        width: 300px;
        height: 100vh;
        position: absolute;
        right: -320px;
        top: 0;
        background-color: white;
        z-index: 9999;
        overflow-y: scroll;

        &.show {
            right: 0px;

        }
        padding: 3rem 2rem;
        .valueDisplay {
            margin-bottom: 1rem;
            .label {
                font-size: 0.7rem;
                font-weight: 600;
                &.eventName {
                    font-size: 0.9rem;
                    margin-top: 3rem;
                }
            }
            .value {
                font-size: 1rem;
                // font-weight: 600;
            }

            .slotSelector {
                margin-top: 5px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                .slot {
                    cursor: pointer;
                    margin: 3px 5px;
                    background-color: white;
                    border: 2px solid rgb(0, 0, 0);
                    border-radius: 18px;
                    padding: 4px 15px;
                    font-size: 0.7rem;
                    color: rgb(5, 5, 5);
                    font-weight: 600;
                    &:hover {
                        background-color: rgb(255, 145, 0);
                        color: rgb(255, 255, 255);
                    }
                    &.active {
                        background-color: rgb(0, 170, 57)!important;
                        color: rgb(255, 255, 255)!important;
                    }
                }
            }
        }

        .bottomActionBar {
            // position: absolute;
            bottom: 2rem;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            button {
                border: none;
                color: white;
                border-radius: 5px;
                font-size: 0.9rem;
                font-weight: 600;
                padding: 5px 25px;
                cursor: pointer;
                &.cancelBtn {
                    background-color: rgb(129, 129, 129);
                }
                &.saveBtn {
                    background-color: rgb(182, 0, 0);
                    &:disabled {
                        background-color: rgba(182, 0, 0, 0.242);
                    }
                }
            }
        }

        .loaderWrapper {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}