.detailsPromptWRapper {
  width: 100%;
  height: 100%;
  position: relative;

  .eventTitle {
    background-color: black;
    width: 100%;
    height: 160px;
    color: white;
    font-size: 1.8rem;
    padding: 28px 45px;

    .date {
      margin-top: 8px;
      font-size: 1rem;
    }

    .timeSLots {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-top: 15px;
      .slot {
        margin-right: 11px;
        font-size: 0.8rem;
        font-weight: 600;
        padding: 5px 8px;
        border-radius: 18px;
        border: 1px solid white;
      }
    }
  }

  .eventDetailsSelector {
    .eventCover {
      width: 100%;
    }
    overflow-y: scroll;
    padding: 20px 25px;
    height: 450px;
    padding-bottom: 50px;
    overflow-x: hidden!important;
    .stretch-card {
      padding-top: 15px;
      padding-bottom: 35px;
      flex-direction: column;
      align-items: center;
      // width: 100%;
    }
    .speakersWrapper {
      margin-top: 35px;
      text-align: center;
      .heading {
        width: 100%;
        margin-bottom: 15px;
        background-color: rgb(172, 0, 0);
        color: white;
        font-size: 0.9rem;
        padding: 4px;
      }
      .speakers {
        text-align: start;
        padding: 6px 6px 6px 21px;
        .speaker {
          padding-top: 6px;
          font-size: 0.8rem;
          font-weight: 600;
        }
      }
    }
  }

  .selectorWrapper {
    width: 100%;
    margin-bottom: -2rem;
    margin-top: 1rem;
    padding: 28px 45px;
    text-align: center;

    .selectors {
      margin-top: 1rem;
      // margin-bottom: -1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .selector {
        width: 150px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(153, 153, 153);
        color: white;
        cursor: pointer;
        font-size: 0.8rem;
        border-radius: 5px;
        margin: 5px 10px;

        &.selected {
          background-color: rgb(179, 0, 0);
        }

        &.disabled {
          background-color: rgb(216, 216, 216);
          cursor: not-allowed;
        }
      }
    }
  }

  .eventActions {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    display: flex;

    .cancel {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 0;
      background-color: rgb(194, 0, 0);
      cursor: pointer;
      color: white;
    }

    .register {
      flex: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 0;
      cursor: pointer;
      background-color: rgb(0, 170, 65);
      color: white;

      &.disabled {
        background-color: rgb(122, 122, 122);
        cursor: not-allowed;
      }
    }
  }
}