.speakerDetailsModalWrapper {
  .detailsWrapper {
    position: relative;
    height: 100%;
    .bottomDismissBar {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 40px;
      font-size: 0.8rem;
      background-color: brown;
      color: white;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: rgb(204, 26, 26);
      }
    }
    .speakerDetails {
  
      width: 100%;
      height: 100%;
      
      padding: 60px 30px 100px 30px;
      overflow-y: scroll;
  
      .companyWrapper {
        display: flex;
        align-items: center;
        .logoPart {
          flex: 5;
          img {
            width: 100%;
            height: 150px;
            object-fit: contain;
          }
        }
        .companyData {
          padding-left: 35px;
          flex: 7;
          .title {
            margin-bottom: 15px;
            font-weight: 600;
            font-size: 1.5rem;
          }
          .subtitle {
            margin-bottom: 15px;
            // font-weight: 600;
            font-size: 1.1rem;
          }
          .description {
            margin-bottom: 15px;
            // font-weight: 600;
            font-size: 0.8rem;
          }
        }
      }
  
      .speakerWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 2.5rem;
        .speakerLabel {
          font-size: 1.6rem;
          // font-weight: 600;
          margin-bottom: 10px;
        }
        .speakerValue {
          font-size: 1.4rem;
          font-weight: 600;
          margin-bottom:35px;
  
        }
        .speakerHeader {
          font-size: 1rem;
          margin-top: 15px;
          font-weight: 600;
          margin-bottom: 35px;
  
        }
        .speakerVideo {
          margin: 15px 0 35px 0;
        }
        .speakerDescription {
          margin-bottom: 15px;
          // font-weight: 600;
          font-size: 1rem;
          // text-align: ce/dnter;
        }
        .contact {
          margin-top: 1rem;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          width: 100%;
          .website {
            cursor: pointer;
            color: brown;
            text-decoration: underline;
            position: relative;
            .detailsViewer {
              position: absolute;
              top: 18px;
              left: 40px;
              width: 300px;
              height: 42px;
              background-color: rgb(190, 0, 0);
              border-radius: 5px;
              box-shadow: 5px 5px 15px rgba(0,0,0,0.2);
              // padding: 2px 20px;
              .action {
                padding: 3px 15px;
                font-size: 0.8rem;
                border-bottom: 1px solid whitesmoke;
                color: white;
                cursor: pointer;
                &:hover {
                background-color: rgb(139, 0, 0);
                }
              }
            }
          }
        }
      }
  
  
  
    }
  }
}