


.featuredSponsorViewer {

    width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      // height: 60px;
      flex-direction: column;
      cursor: pointer;
      margin-top: 3rem;
      .featuredSponsorLabel {
        font-weight: 600;
    }
      img {
        margin-top: 2rem;
        height: 100px;
        width: 230px;
        object-fit: contain;
      }
  }