$small: 300px;
$medium: 1024px;

* {
  transition: 0.3s !important;
}

.registered-events-page-outer-wrapper {
  width: 100%;
  height: 85vh;
  overflow-y: scroll;

  .cardsWrapper {
    padding: 2rem 4rem;

    @media screen and (max-width: $medium) {
      padding: 2rem 0;
    }

    .eventCard {
      position: relative;

      width: 100%;
      height: 140px;
      background-color: rgb(6, 187, 0);
      margin-bottom: 1rem;
      color: white;
      border-radius: 5px;
      box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.05);
      display: flex;
      border: 1px solid white;
      cursor: pointer;

      @media screen and (max-width: $medium) {
        min-height: 250px;
      }

      @media screen and (max-width: $medium) {
        flex-direction: column;
      }

      .dateHighlighter {
        width: 150px;
        height: 140px;
        background-color: #ad031d;
        display: flex;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media screen and (max-width: $medium) {
          width: 100%;
        }

        .day {
          color: white;
          font-size: 2.8rem;
          // font-weight: 600;
        }

        .month {
          color: white;
          font-size: 1.5rem;
          // font-weight: 600;
        }
      }

      .detailsPart {
        padding: 10px 15px;

        .eventName {
          font-weight: 600;
          font-size: 1.5rem;
        }

        .eventDate {
          // font-weight: 600;
          font-size: 0.9rem;
          margin: 5px 0 35px 0;
        }

        .eventTimeSlots {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .timeSlot {
            border: 1px solid black;
            font-size: 0.7rem;
            padding: 4px 6px;
            display: flex;
            margin-right: 9px;
            border-radius: 15px;

            &.registered {
              border: 1px solid black;
              background-color: rgb(53, 53, 53);
              color: white;
            }

            .focus {
              padding-left: 8px;
              margin-left: 8px;
              border-left: 1px solid white;
            }
          }
        }
      }

      .registerTag {
        position: absolute;

        @media screen and (max-width: $medium) {
          position: unset;
        }

        top: -1px;
        right: 25px;
        background-color: green;
        padding: 8px 35px;
        color: white;
        font-size: 0.7rem;
        font-weight: 600;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        text-align: center;
      }

      &:hover {
        border: 1px solid #b1031d;
        box-shadow: 4px 4px 5px rgba(0, 0, 0, 0);

      }
    }
  }

  .eventLogoWrapper {
    img {
      width: 100%;
    }
  }

  .speakerLogos {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    // flex-direction: column;
    flex-wrap: wrap;

    img {
      padding: 0 20px;
      cursor: pointer;
      height: 60px;
      object-fit: contain;
      width: 100%;
      margin-bottom: 15px;
    }
  }


}