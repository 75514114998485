$small: 300px;
$medium: 1024px;

* {
  transition: 0.3s !important;
}

.page-outer-wrapper {
  width: 100%;
  height: 85vh;
  overflow-y: scroll;

  .cardsWrapper {
    padding: 2rem 4rem;

    @media screen and (max-width: $medium) {
      padding: 2rem 0;
    }

    .eventCard {
      position: relative;

      width: 100%;
      height: 140px;
      background-color: white;
      margin-bottom: 1rem;
      border-radius: 5px;
      box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.05);
      display: flex;
      border: 1px solid white;
      // cursor: pointer;

      @media screen and (max-width: $medium) {
        min-height: 250px;
      }

      @media screen and (max-width: $medium) {
        flex-direction: column;
      }

      .dateHighlighter {
        width: 150px;
        height: 140px;
        background-color: #ad031d;
        display: flex;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @media screen and (max-width: $medium) {
          width: 100%;
        }

        .day {
          color: white;
          font-size: 2.8rem;
          // font-weight: 600;
        }

        .month {
          color: white;
          font-size: 1.5rem;
          // font-weight: 600;
        }
      }

      .detailsPart {
        width: 70%;
        padding: 10px 15px;

        .eventName {
          font-weight: 600;
          font-size: 1.5rem;
        }

        .eventDate {
          // font-weight: 600;
          font-size: 0.9rem;
          margin: 5px 0 35px 0;
        }

        .eventTimeSlots {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .timeSlot {
            border: 1px solid black;
            font-size: 0.7rem;
            padding: 4px 6px;
            margin-right: 9px;
            border-radius: 15px;

            &.registered {
              border: 1px solid black;
              background-color: green;
              color: white;
            }
          }
        }
      }

      .actionWRapper {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .detailsBtn {
          background-color: rgb(40, 95, 247);
          font-size: 0.7rem;
          padding: 6px 28px;
          margin-bottom: 10px;
          color: white;
          border-radius: 4px;
          cursor: pointer;
        }

        .registerBtn {
          background-color: rgb(3, 175, 75);
          font-size: 0.7rem;
          padding: 6px 25px;
          color: white;
          border-radius: 4px;
          cursor: pointer;
        }
      }

      .registerTag {
        position: absolute;

        @media screen and (max-width: $medium) {
          position: unset;
        }

        top: -1px;
        right: 25px;
        background-color: green;
        padding: 8px 35px;
        color: white;
        font-size: 0.7rem;
        font-weight: 600;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        text-align: center;
      }
    }
  }
  .eventLogoWrapper {
    img {
      width: 100%;
    }
  }

}

.swal2-popup {
  padding-top: 15px !important;
}


.eventOuterWrapper {
  padding-top: 5rem;
  margin-bottom: -2rem;
}

.registeredActions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  button {
    margin-top: 15px;
    background-color: rgb(153, 153, 153);
    border: 1px solid grey;
    color: white;
    font-size: 0.9rem;
    padding: 5px 15px;
    width: 230px;
    cursor: not-allowed;
    border-radius: 5px;

    &.downloadBtn {
      background-color: rgb(216, 40, 40);
      border: 1px solid rgb(209, 0, 0);
      cursor: pointer;
    }

    &.goto_webinar {
      cursor: pointer;
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(209, 0, 0);
      * {
        color: rgb(255, 25, 25);
      }
      color: rgb(255, 25, 25);
      &:hover {
        cursor: pointer;
      background-color: rgb(216, 40, 40);
      border: 1px solid rgb(209, 0, 0);
      color: rgb(252, 252, 252);
      * {
      color: rgb(252, 252, 252);
      }
    }
  }
    &.cancelBtn {
      cursor: pointer;
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(209, 0, 0);
      * {
        color: rgb(255, 25, 25);
      }
      color: rgb(255, 25, 25);
      &:hover {
        cursor: pointer;
      background-color: rgb(216, 40, 40);
      border: 1px solid rgb(209, 0, 0);
      color: rgb(252, 252, 252);
      * {
      color: rgb(252, 252, 252);
      }
      }
    }
  }
}

.messageBox {
  margin-top: 15px;
  margin-bottom: 25px;
  font-size: 0.8rem;
  font-weight: 600;
  border: 1px solid rgba(155, 0, 26, 0.795);
  border-radius: 7px;
  color: rgba(155, 0, 26, 0.795);
  padding: 8px 15px;
  text-align: center;
}

.__react_component_tooltip {
  transition: 0s!important;
}

.speakerLogos {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  // flex-direction: column;
  flex-wrap: wrap;
  .imageWRapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 100%;

    &:hover {
      img {
        // height: 110px;
        // width: 100%;
      }
    }

    img {
      width: 100%;
      padding: 0 20px;
      height: 100px;
      object-fit: contain;
      cursor: pointer;
      // width: 100%;  
      margin-bottom: 15px;
      
    }
  }
}