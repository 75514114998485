.tableActions {
  display: flex;
  width: 100%;
  margin-bottom: 2rem;
  margin-top: 4rem;
  justify-content: space-between;

  .leftPart {
    input {
      border-radius: 4px;
      border: 1px solid brown;
      padding: 8px 15px;
      width: 400px;
      font-size: 0.8rem;
    }
  }

  .rightPart {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    select {
      background-color: rgb(255, 255, 255);
      padding: 8px 16px;
      font-size: 0.8rem;
      margin: 0 8px;
      width: 200px;
      cursor: pointer;
      border: 1px solid brown;
      border-radius: 5px;
    }

    button {
      background-color: rgb(163, 0, 0);
      color: white;
      padding: 10px 27px;
      font-size: 0.8rem;
      margin-right: 15px;
      border: none;
      border-radius: 5px;
    }
  }
}

.adminPageWrapper {
  padding: 5px 15px;

  .pageTitle {
    font-size: 1.4rem;
    margin-top: 2rem;
    width: 100%;
    text-align: center;
  }
}

.admin-welcome {
  margin-left: -5rem;
}

.rdt_TableCell {
  min-width: 1px !important;
  width: unset !important;

  &>div {
    white-space: unset !important;
    overflow: hidden;
    text-overflow: unset !important;
  }
}

.timeSlotUpdateBtn {
  background-color: rgb(182, 0, 0);
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 0.7rem;
  font-weight: 600;
  padding: 2px 7px;
  cursor: pointer;
}

.rdt_TableCol {}

.staticButtonWrapper {
  text-align: left;
  margin: 1rem 0;

  .staticUploadButton {
    background-color: #087700;
    color: white;
    padding: 8px 20px;
    font-size: 0.9rem;
    border: none;
    border-radius: 4px;
    margin-bottom: 15px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }

  .staticBulkEmailButton {
    background-color: #003677;
    color: white;
    padding: 8px 20px;
    font-size: 0.9rem;
    border: none;
    border-radius: 4px;
    margin-bottom: 15px;
    margin-left: 20px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #0056b3;
    }
  }
}
