.registerPromptWRapper {
  width: 100%;
  height: 100%;
  position: relative;

  .eventTitle {
    background-color: black;
    width: 100%;
    height: 120px;
    color: white;
    font-size: 1.8rem;
    padding: 28px 45px;

    .date {
      margin-top: 8px;
      font-size: 1rem;
    }
  }

  .eventDetailsSelector {
    overflow-y: scroll;
    height: 330px;
    padding-bottom: 50px;
  }

  .selectorWrapper {
    width: 100%;
    margin-bottom: -2rem;
    margin-top: 1rem;
    padding: 28px 45px;
    text-align: center;

    .selectors {
      margin-top: 1rem;
      // margin-bottom: -1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .selector {
        width: 150px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(153, 153, 153);
        color: white;
        cursor: pointer;
        font-size: 0.8rem;
        border-radius: 5px;
        margin: 5px 10px;

        &.selected {
          background-color: rgb(179, 0, 0);
        }

        &.disabled {
          background-color: rgb(216, 216, 216);
          cursor: not-allowed;
        }
      }
    }
  }

  .eventActions {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    display: flex;

    .cancel {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 0;
      background-color: rgb(194, 0, 0);
      cursor: pointer;
      color: white;
    }

    .register {
      flex: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 0;
      cursor: pointer;
      background-color: rgb(0, 170, 65);
      color: white;

      &.disabled {
        background-color: rgb(122, 122, 122);
        cursor: not-allowed;
      }
    }
  }
}