.castars {
  padding-left: 15px;
  li {
    font-size: 1.2rem;
    font-weight: 600;
  }
}

.speakerLogo.caLogo {
  cursor: default!important;

}

.caDetails {
  width: 100%;
  text-align: center;
  margin: 2rem 0;
  font-size: 1.6rem;
  font-weight: 600;
  // background-color: aqua;
  // text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.eventCardOuterWrapper {
  width: 100%;
  min-height: 430px;
  background-color: white;
  margin-bottom: 6rem;
  margin-top: 1rem;
  border-radius: 5px;
  box-shadow: 8px 8px 10px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(100, 100, 100, 0.5);
  position: relative;

  .dateHighlighter {
    position: absolute;
    width: 280px;
    border-radius: 8px;
    top: -30px;
    left: 75px;
    height: 60px;
    background-color: rgb(131, 0, 0);
    background-position: bottom;
    background-size: cover;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;

    .mdi {
      font-size: 2rem;
    }

    p {
      padding-left: 12px;
      padding-top: 2px;
      font-size: 1.3rem;
    }
  }

  .contentWrapper {
    padding: 4.3rem 3rem 2rem 3rem;
  }

  .stretch-card {
    flex-direction: column;
  }

  .registerActions {
    text-align: center;
  }

  .eventTimeSlots {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;

    .timeSlot {
      border: 1px solid black;
      font-size: 0.9rem;
      padding: 4px 6px;
      margin-top: 9px;
      border-radius: 15px;
      width: 250px;
      cursor: pointer;

      &.registered {
        border: 1px solid black !important;
        background-color: rgb(136, 0, 0) !important;
        color: white;
        cursor: not-allowed;
      }

      &.disabled {
        border: 1px solid rgb(161, 159, 159) !important;
        background-color: rgb(173, 173, 173) !important;
        color: white;
        cursor: not-allowed;
      }

      &:hover {
        border: 1px solid black;
        background-color: rgb(1, 165, 1);
        color: white;
      }
    }
  }

  .speakersWrapper {
    margin-top: 35px;
    text-align: center;

    .heading {
      width: 100%;
      margin-bottom: 15px;
      background-color: rgb(129, 0, 0);
      color: white;
      font-size: 0.9rem;
      padding: 4px;
    }

    .speakers {
      text-align: start;
      padding: 6px 6px 6px 21px;

      .speaker {
        padding-top: 6px;
        font-size: 0.8rem;
        font-weight: 600;
      }
    }
  }

  h6 {
    margin-top: 4px;
    margin-bottom: 25px;
  }
}
.sponsorsLabels {
  margin-top: 15px;
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 1rem;
  color: rgb(129, 0, 0);
}
.sponsorsRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .sponsorWrapper {
    margin: 10px 15px;
    // width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    cursor: pointer;
    img {
      height: 180px;
      width: 400px;
      object-fit: contain;
    }
  }
}

.custom-popup-class {

  
  
  .swal2-confirm {
    background-color: #007bff !important;
    border: none;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .swal2-cancel {
    background-color: #6c757d !important;
    border: none;
    color: white;
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .swal2-confirm:focus, .swal2-cancel:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0,123,255,.5);
  }
  
  
  .swal2-html-container {
    width: 426px;
  }
  
  .swal2-title {
    margin-bottom: 20px;
  }
}