.error-message {
    display: flex;
    align-items: center;
    background-color: #f8d7da; /* Light red background */
    color: #721c24; /* Dark red text color */
    padding: 10px 15px;
    border: 1px solid #f5c6cb;
    border-radius: 5px;
    font-size: 14px;
    margin-bottom: 15px;
  
    .icon {
      margin-right: 10px;
      font-size: 18px;
      color: #f44336; /* Red color for the icon */
    }
  }
  