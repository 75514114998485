
.certDownloadWrapper {
    width: 10.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .downloadBtn {
      cursor: pointer;
      margin-left: -5px;
    }
    padding-right: 10px;
    &.disable {
        pointer-events: none;
        opacity: 0.1;
    }
  }