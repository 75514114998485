.downloadPageWrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .downloadWrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        // border: 1px solid red;
        // padding
        img {
            width: 60px;
        }

        p {
            margin-bottom: 0;
            font-weight: 600;
            padding-left: 20px;
            cursor: pointer;
            a {
                padding-top: 5px;
                color: rgb(145, 0, 0);
            }
        }
    }

    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;

        .concordeLogo {

            height: 20px;
        }
    }
}