.login-page-wrapper {
  background-image: url("https://img1.goodfon.com/wallpaper/nbig/3/ef/samolet-airplane-passazhirskiy.jpg");
  background-position: center;
  background-size: cover;

  .content-wrapper {
    background-color: rgba(155, 0, 26, 0.795) !important;
  }

  .brand-logo {
    img {
      width: 157px!important;
    }
  }
}

.field-error-wrapper {
  height: 5px;
  font-size: 0.7rem;
  font-weight: 600;
  color: red;
  padding-top: 4px;
  padding-left: 25px;
}